
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Author } from '@umax/roditeli-types/entity';

const author = namespace('Authors');

@Component({})
export default class AuthorSelector extends Vue {
    @author.State((state) => state.authors)
    authors!: Author[];

    @Prop({ default: null })
    value!: string;

    @Emit('input')
    handleChange(data: string): string {
        return data;
    }
}
