
import { Vue, Component, Emit, Ref, Prop } from 'vue-property-decorator';
import RichEditor from '@/components/shared/RichEditor.vue';
import FileUploader from '@/components/shared/FileUploader.vue';
import SEODrawer from '@/components/modals/crud/SEODrawer.vue';
import { Banner } from '@/store/types/BannerTypes';

@Component({
    components: { SEODrawer, FileUploader, RichEditor },
})
export default class BannersCrud extends Vue {
    @Ref('formInstance')
    readonly formInstance!: { validate: () => Promise<boolean>; resetFields: () => void };

    @Prop({ default: false })
    visible!: boolean;

    @Prop({ default: null })
    private dataBanner!: Banner;

    data() {
        return {
            form: this.dataBanner
                ? { ...(this.dataBanner as Banner) }
                : ({
                      bannerName: '',
                      buttonLink: '',
                      buttonText: '',
                      visible: false,
                      subscribe: false,
                      image: '',
                      text: '',
                      erid: '',
                  } as Banner),
        };
    }

    private seoDrawer = false;

    private rules = {
        bannerName: [
            { required: true, message: 'Введите название баннера', trigger: 'blur' },
        ],
        buttonLink: [
            { required: true, message: 'Введите ссылку кнопки', trigger: 'blur' },
            {
                pattern: /(^http:\/\/|^https:\/\/)/i,
                message: 'Должна быть ссылка',
                trigger: 'blur',
            },
        ],
        buttonText: [
            { required: true, message: 'Введите текст кнопки', trigger: 'blur' },
            { max: 25, message: 'Максимум 25 символов', trigger: 'change' },
        ],
        text: [{ required: true, message: 'Введите текст баннера', trigger: 'blur' }],
    };

    @Emit('cancel')
    handleCancel(): void {}

    @Emit('onOk')
    onOk(data: Banner): Banner {
        return data;
    }

    private handleVisibleChange(visible: boolean): void {
        if (!visible) {
            this.formInstance.resetFields();
            this.$data.form.image = '';
        }
    }

    async handleSubmit(): Promise<void> {
        const isValid = await this.formInstance.validate();

        if (isValid) {
            if (this.dataBanner) {
                const newForm: Banner = {
                    ...this.$data.form,
                    updatedAt: new Date().toISOString(),
                };
                this.onOk(newForm as Banner);
            } else {
                this.onOk(this.$data.form as Banner);
            }
        } else {
            this.$message.error('Ошибка валидации');
        }
    }
}
