
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { pick } from 'lodash';
import RichEditor from '@/components/shared/RichEditor.vue';
import FileUploader from '@/components/shared/FileUploader.vue';
import { CreateVideoDto, UpdateVideoDto } from '@umax/roditeli-types/dto';
import { Video } from '@umax/roditeli-types/entity';
import AuthorSelector from '@/components/shared/AuthorSelector.vue';
import { VideoType } from '@umax/roditeli-types/enums';
import SEODrawer from '@/components/modals/crud/SEODrawer.vue';
import { replaceScriptTemplate } from '@/utils/replaceScriptTemplate';

@Component({
    components: { SEODrawer, AuthorSelector, FileUploader, RichEditor },
})
export default class VideosCRUD extends Vue {
    @Ref('formInstance')
    readonly formInstance!: { validate: () => Promise<boolean> };

    @Prop({ default: false })
    visible!: boolean;

    @Prop({ default: null })
    private dataVideo!: Video;

    data() {
        return {
            form: this.dataVideo
                ? {
                      ...(pick(this.dataVideo, [
                          'title',
                          'description',
                          'url',
                          'image',
                          'visible',
                          'authorId',
                          'type',
                          'seo',
                          'alias',
                          'illustrator',
                          'isMain',
                      ]) as UpdateVideoDto),
                      hashtags: this.dataVideo.hashtags || [],
                  }
                : ({
                      title: '',
                      description: '',
                      url: '',
                      image: '',
                      visible: false,
                      authorId: '',
                      type: VideoType.LOCAL,
                      hashtags: [],
                      seo: {
                          image: '',
                          title: '',
                          description: '',
                      },
                      alias: '',
                      illustrator: '',
                      isMain: false,
                  } as CreateVideoDto),
        };
    }

    private rules = {
        authorId: [{ required: true, message: 'Выберите автора', trigger: 'blur' }],
        title: [
            { required: true, message: 'Введите заголовок', trigger: 'blur' },
            {
                min: 10,
                message: 'Заголовое должен быть не менее 10 символов',
                trigger: 'blur',
            },
        ],
        url: [{ type: 'url', message: 'Введите валидную ссылку', trigger: 'blur' }],
        description: [
            { required: true, message: 'Введите описание', trigger: 'blur' },
            {
                min: 10,
                message: 'Описание должен быть не менее 10 символов',
                trigger: 'blur',
            },
        ],
    };

    private seoDrawer = false;

    @Emit('cancel')
    handleCancel(): void {}

    @Emit('onOk')
    onOk(data: CreateVideoDto | UpdateVideoDto): CreateVideoDto | UpdateVideoDto {
        if (data.description) data.description = replaceScriptTemplate(data.description);

        return data;
    }

    get isVideo(): boolean {
        if (!this.$data.form.url) {
            return false;
        }
        return !!this.videoType;
    }

    get videoType(): string | null {
        if (!this.$data.form.url) {
            return null;
        }

        if (this.$data.form.url.endsWith('.mp4')) {
            return 'video/mp4';
        }

        if (this.$data.form.url.endsWith('.avi')) {
            return 'video/x-msvideo';
        }
        return null;
    }

    async handleSubmit(): Promise<void> {
        try {
            const isValid = await (
                this.$refs.formInstance as unknown as {
                    validate: () => Promise<boolean>;
                }
            ).validate();
            if (isValid) {
                this.onOk(this.$data.form);
            }
        } catch (e: any) {
            this.$message.error('Ошибка валидации');
        }
    }

    handleVisibleChange(visible: boolean): void {
        if (!visible) {
            // @ts-ignore
            this.$refs.formInstance.resetFields();
            this.$data.form.image = '';
            this.$data.form.url = '';
            this.$data.form.seo = {
                image: '',
                title: '',
                description: '',
            };
        }
    }

    @Watch('form.title')
    handleChangeTitle(val: string): void {
        this.$data.form.alias = this.translitText(val);
    }
}
