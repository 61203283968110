import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import { CreateUserDto, UpdateUserDto } from '@umax/roditeli-types/dto';
import { User } from '@umax/roditeli-types/entity';

@Module({ namespaced: true })
export class Users extends VuexModule {
    users: User[] = [];

    @Mutation
    setUsers(data: User[]): void {
        this.users = data;
    }

    @Action({ commit: 'setUsers', rawError: true })
    async fetchUsers(): Promise<void> {
        const { data } = await axios.get('/admin/users');
        return data;
    }

    @Action({ rawError: true })
    async createUser(data: CreateUserDto): Promise<void> {
        return axios.post('/admin/users', data);
    }

    @Action({ rawError: true })
    async updateUser(data: { user: UpdateUserDto; id: string }): Promise<void> {
        const { user, id } = data;
        return axios.patch('/admin/users', user, {
            params: {
                id,
            },
        });
    }

    @Action({ rawError: true })
    async removeUser(id: string): Promise<void> {
        return axios.delete(`/admin/users/${id}`);
    }
}
