
import { Vue, Component, Emit, Ref, Prop } from 'vue-property-decorator';
import { pick } from 'lodash';
import RichEditor from '@/components/shared/RichEditor.vue';
import { CreateCardDto, UpdateCardDto } from '@umax/roditeli-types/dto';
import { Card } from '@umax/roditeli-types/entity';
import FileUploader from '@/components/shared/FileUploader.vue';
import AuthorSelector from '@/components/shared/AuthorSelector.vue';
import SEODrawer from '@/components/modals/crud/SEODrawer.vue';
import { namespace } from 'vuex-class';
import { replaceScriptTemplate } from '@/utils/replaceScriptTemplate';

const hashtagsStore = namespace('Hashtags');

@Component({
    components: { SEODrawer, AuthorSelector, FileUploader, RichEditor },
})
export default class CardsCrud extends Vue {
    @Ref('formInstance')
    readonly formInstance!: { validate: () => Promise<boolean>; resetFields: () => void };

    @Prop({ default: false })
    visible!: boolean;

    @Prop({ default: null })
    private dataCard!: Card;

    @hashtagsStore.State((state) => state.hashtags)
    hashtags!: string[];

    data() {
        return {
            form: this.dataCard
                ? {
                      ...(pick(this.dataCard, [
                          'title',
                          'text',
                          'background',
                          'visible',
                          'image',
                          'authorId',
                          'seo',
                          'alias',
                          'hashtags',
                          'illustrator',
                          'isMain',
                      ]) as UpdateCardDto),
                      text:
                          this.dataCard.text && this.dataCard.text.length
                              ? this.dataCard.text
                              : [''],
                  }
                : ({
                      title: '',
                      text: [''],
                      visible: false,
                      authorId: '',
                      image: '',
                      background: '',
                      hashtags: [],
                      seo: {
                          image: '',
                          title: '',
                          description: '',
                      },
                      alias: '',
                      isMain: false,
                      illustrator: '',
                  } as CreateCardDto),
            rawTexts: this.uniqifyTexts(
                this.dataCard?.text?.length ? this.dataCard.text : [''],
            ),
        };
    }

    private seoDrawer = false;

    private rules = {
        title: [
            { required: true, message: 'Введите заголовок', trigger: 'blur' },
            {
                min: 10,
                message: 'Заголовок должен быть не менее 10 символов',
                trigger: 'blur',
            },
        ],
        background: [{ required: true, message: 'Выберите фон', trigger: 'blur' }],
    };

    @Emit('cancel')
    handleCancel(): void {}

    @Emit('onOk')
    onOk(data: CreateCardDto): CreateCardDto {
        return {
            ...data,
            text: this.$data.rawTexts.map(({ text }: { text: string }) =>
                replaceScriptTemplate(text),
            ),
        };
    }

    @Emit('onOk')
    onOkUpdate(data: UpdateCardDto): UpdateCardDto {
        return {
            ...data,
            text: this.$data.rawTexts.map(({ text }: { text: string }) =>
                replaceScriptTemplate(text),
            ),
        };
    }

    @hashtagsStore.Action
    fetchTags!: () => void;

    mounted(): void {
        this.fetchTags();
    }

    async handleSubmit(): Promise<void> {
        const isValid = await this.formInstance.validate();
        if (isValid) {
            if (this.dataCard) {
                this.onOkUpdate(this.$data.form);
            } else {
                this.onOk(this.$data.form as CreateCardDto);
            }
        } else {
            this.$message.error('Ошибка валидации');
        }
    }

    private handleVisibleChange(visible: boolean): void {
        if (!visible) {
            this.formInstance.resetFields();
            // @ts-ignore
            this.$data.form.image = '';
            this.$data.form.seo = {
                image: '',
                title: '',
                description: '',
            };
        }
    }

    get texts(): string[] {
        if (this.$data.form.text) {
            return this.$data.form.text;
        }
        throw new Error('Вы не должны сюда попасть');
    }

    private generateAlias(): void {
        this.$data.form.alias = this.translitText(this.$data.form.title as string);
    }

    addText(): void {
        this.$data.rawTexts.push({
            text: '',
            id: this.generateId(),
        });
    }

    removeText(id: string): void {
        this.$data.rawTexts = this.$data.rawTexts.filter(
            ({ id: currentId }: { id: string }) => id !== currentId,
        );
    }

    generateId(): string {
        return Math.round(Math.random() * 10000).toString();
    }

    uniqifyTexts(arr: string[]): { text: string; id: string }[] {
        return arr.map((text) => ({
            text: text,
            id: this.generateId(),
        }));
    }
}
