
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { LoginDto } from '@umax/roditeli-types/dto';
import { namespace } from 'vuex-class';

const auth = namespace('Auth');

@Component
export default class AuthModal extends Vue {
    @Prop({ default: false }) visible!: boolean;

    private form = {
        email: '',
        password: '',
    };

    @auth.State((state) => state.isLoginError) isLoginError: boolean | undefined;

    private rules = {
        email: [
            { required: true, message: 'Введите email', trigger: 'blur' },
            { type: 'email', message: 'Введите валидный email', trigger: 'blur' },
        ],
        password: [{ required: true, message: 'Введите пароль', trigger: 'blur' }],
    };

    private handleSubmit() {
        this.onOk(this.form);
    }

    @auth.Mutation
    public setIsLoginError!: (data: boolean) => void;

    @Emit('cancel')
    handleCancel(): void {}

    @Emit('onOk')
    onOk(data: LoginDto): LoginDto {
        return data;
    }

    @Watch('visible')
    visibleChanged(): void {
        this.form = {
            email: '',
            password: '',
        };
        this.setIsLoginError(false);
    }
}
