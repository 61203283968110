
import { format } from 'date-fns/esm';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Banner, IFilteredColumn, ITableColumns } from '../store/types/BannerTypes';
import BannersCRUD from '@/components/modals/crud/BannersCRUD.vue';

@Component({
    components: { BannersCRUD },
})
export default class Banners extends Vue {
    private crudModal = false;
    private currentBanner: Banner | null = null;

    get columns(): ITableColumns[] {
        return [
            {
                title: 'Название баннера',
                dataIndex: 'bannerName',
                key: 'bannerName',
                filters: this.getFilteredColumns,
                onFilter: (value: string, record: Banner): boolean =>
                    record.bannerName === value,
            },
            {
                title: 'Активный',
                dataIndex: 'visible',
                key: 'visible',
                scopedSlots: { customRender: 'visible' },
                sorter: (a: Banner, b: Banner) => a.visible > b.visible,
            },
            {
                title: 'Дата создания',
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: '20%',
                sorter: (a: Banner, b: Banner) => a.createdAt > b.createdAt,
                scopedSlots: { customRender: 'createdAt' },
            },
            {
                title: 'Дата обновления',
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                width: '20%',
                sorter: (a: Banner, b: Banner) => a.updatedAt > b.updatedAt,
                scopedSlots: { customRender: 'updatedAt' },
            },
            {
                title: 'Действия',
                dataIndex: '',
                key: 'x',
                scopedSlots: { customRender: 'action' },
            },
        ];
    }

    get getFilteredColumns(): IFilteredColumn[] {
        return this.getBanners.map(
            (banner: Banner): IFilteredColumn => ({
                text: banner.bannerName,
                value: banner.bannerName,
            }),
        );
    }

    get getBanners(): Banner[] {
        return this.$store.getters['Banners/getBanners'];
    }

    private formatDate(date: string): string {
        return format(new Date(date), 'dd.MM.yy HH:mm');
    }

    private toggleModal() {
        if (this.crudModal) {
            this.currentBanner = null;
        }
        this.crudModal = !this.crudModal;
    }

    private editBanner(data: Banner): void {
        this.currentBanner = data;
    }

    private async handleCreateBanner(data: Banner): Promise<void> {
        await this.$store.dispatch('Banners/createBanner', data);
        await this.$store.dispatch('Banners/fetchBanners');
        this.toggleModal();
    }

    private async handleUpdateBanner(data: Banner): Promise<void> {
        if (this.currentBanner) {
            await this.$store.dispatch('Banners/updateBanner', data);
            await this.$store.dispatch('Banners/fetchBanners');
            this.toggleModal();
        }
    }

    private async handleDeleteBanner(id: string): Promise<void> {
        await this.$store.dispatch('Banners/deleteBanner', id);
        await this.$store.dispatch('Banners/fetchBanners');
    }

    async mounted(): Promise<void> {
        try {
            await this.$store.dispatch('Banners/fetchBanners');
        } catch (e: any) {
            this.$message.error('У вас недостаточно прав для этой страницы');
        }
    }

    @Watch('currentBanner')
    handleChange(val: Banner): void {
        if (val) {
            this.toggleModal();
        }
    }
}
