
import { Vue, Component, Emit, Ref, Prop } from 'vue-property-decorator';
import { pick } from 'lodash';
import RichEditor from '@/components/shared/RichEditor.vue';
import { CreateTextDto, UpdateTextDto } from '@umax/roditeli-types/dto';
import { Text } from '@umax/roditeli-types/entity';
import FileUploader from '@/components/shared/FileUploader.vue';
import AuthorSelector from '@/components/shared/AuthorSelector.vue';
import SEODrawer from '@/components/modals/crud/SEODrawer.vue';
import { namespace } from 'vuex-class';
import { replaceScriptTemplate } from '@/utils/replaceScriptTemplate';

const hashtagsStore = namespace('Hashtags');

@Component({
    components: { SEODrawer, AuthorSelector, FileUploader, RichEditor },
})
export default class TextsCRUD extends Vue {
    @Ref('formInstance')
    readonly formInstance!: { validate: () => Promise<boolean>; resetFields: () => void };

    @Prop({ default: false })
    visible!: boolean;

    @Prop({ default: null })
    private dataText!: Text;

    @hashtagsStore.State((state) => state.hashtags)
    hashtags!: string[];

    data() {
        return {
            form: this.dataText
                ? {
                      ...(pick(this.dataText, [
                          'title',
                          'text',
                          'subtitle',
                          'image',
                          'visible',
                          'authorId',
                          'seo',
                          'alias',
                          'illustrator',
                          'isMain',
                          'audioFile',
                          'imageAlt',
                          'imageTitle',
                      ]) as UpdateTextDto),
                      hashtags: this.dataText.hashtags || [],
                  }
                : ({
                      title: '',
                      text: '',
                      subtitle: '',
                      image: '',
                      visible: false,
                      authorId: '',
                      hashtags: [],
                      seo: {
                          image: '',
                          title: '',
                          description: '',
                      },
                      alias: '',
                      illustrator: '',
                      imageTitle: '',
                      imageAlt: '',
                      isMain: true,
                      audioFile: '',
                  } as CreateTextDto),
        };
    }

    private seoDrawer = false;

    private rules = {
        title: [
            { required: true, message: 'Введите заголовок', trigger: 'blur' },
            {
                min: 10,
                message: 'Заголовое должен быть не менее 10 символов',
                trigger: 'blur',
            },
        ],
        subtitle: [
            { required: true, message: 'Введите описание', trigger: 'blur' },
            {
                min: 10,
                message: 'Описание должен быть не менее 10 символов',
                trigger: 'blur',
            },
        ],
    };

    @Emit('cancel')
    handleCancel(): void {}

    @Emit('onOk')
    onOk(data: CreateTextDto): CreateTextDto {
        if (data.text) data.text = replaceScriptTemplate(data.text);

        return data;
    }

    @Emit('onOk')
    onOkUpdate(data: UpdateTextDto): UpdateTextDto {
        if (data.text) data.text = replaceScriptTemplate(data.text);

        return data;
    }

    @hashtagsStore.Action
    fetchTags!: () => void;

    mounted(): void {
        this.fetchTags();
    }

    async handleSubmit(): Promise<void> {
        const isValid = await (
            this.$refs.formInstance as unknown as {
                validate: () => Promise<boolean>;
            }
        ).validate();
        if (isValid) {
            if (this.dataText) {
                this.onOkUpdate(this.$data.form);
            } else {
                this.onOk(this.$data.form as CreateTextDto);
            }
        } else {
            this.$message.error('Ошибка валидации');
        }
    }

    private handleVisibleChange(visible: boolean): void {
        if (!visible) {
            // @ts-ignore
            this.$refs.formInstance.resetFields();
            this.$data.form.image = '';
            this.$data.form.seo = {
                image: '',
                title: '',
                description: '',
            };
        }
    }

    private generateAlias(): void {
        this.$data.form.alias = this.translitText(this.$data.form.title as string);
    }

    get isAudio(): boolean {
        if (!this.$data.form.audioFile) {
            return false;
        }
        return this.$data.form.audioFile.endsWith('.mp3');
    }
}
