import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import { CreatePdfDto, UpdatePdfDto } from '@umax/roditeli-types/dto';
import { Pdf } from '@umax/roditeli-types/entity';

@Module({ namespaced: true })
export class Pdfs extends VuexModule {
    pdfs: Pdf[] = [];

    @Mutation
    setPdfs(data: Pdf[]): void {
        this.pdfs = data;
    }

    @Action({ commit: 'setPdfs', rawError: true })
    async fetchPdfs(): Promise<void> {
        const { data } = await axios.get('/admin/pdfs');

        if (data.length) {
            const origin = window.location.origin;

            let url = 'http://localhost:3000';

            if (origin === 'https://cms-roditeli.umax.dev')
                url = 'https://roditeli.umax.dev';

            if (
                origin === 'https://roditeli.maximumtest.ru' ||
                origin === 'https://cms.roditeli.maximumtest.ru'
            )
                url = 'https://roditeli.maximumtest.ru';

            for (const item of data) {
                item.source =
                    url +
                    axios.defaults.baseURL +
                    '/pdfs/?pdf=' +
                    item?.url?.split('/')?.pop();
            }
        }

        return data;
    }

    @Action({ rawError: true })
    async createPdf(data: CreatePdfDto): Promise<void> {
        return axios.post('/admin/pdfs', data);
    }

    @Action({ rawError: true })
    async updatePdf(data: { pdf: UpdatePdfDto; id: string }): Promise<void> {
        const { pdf, id } = data;
        return axios.patch('/admin/pdfs', pdf, {
            params: {
                id,
            },
        });
    }

    @Action({ rawError: true })
    async removePdf(id: string): Promise<void> {
        return axios.delete(`/admin/pdfs/${id}`);
    }
}
