import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import { CreateAuthorDto, UpdateAuthorDto } from '@umax/roditeli-types/dto';
import { Author } from '@umax/roditeli-types/entity';

@Module({ namespaced: true })
export class Authors extends VuexModule {
    authors: Author[] = [];

    @Mutation
    setAuthors(data: Author[]): void {
        this.authors = data;
    }

    @Action({ commit: 'setAuthors', rawError: true })
    async fetchAuthors(): Promise<void> {
        const { data } = await axios.get('/admin/authors');
        return data;
    }

    @Action({ rawError: true })
    async createAuthor(data: CreateAuthorDto): Promise<void> {
        return axios.post('/admin/authors', data);
    }

    @Action({ rawError: true })
    async updateAuthor(data: { author: UpdateAuthorDto; id: string }): Promise<void> {
        const { author, id } = data;
        return axios.patch('/admin/authors', author, {
            params: {
                id,
            },
        });
    }

    @Action({ rawError: true })
    async removeAuthor(id: string): Promise<void> {
        return axios.delete(`/admin/authors/${id}`);
    }
}
