
import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { User } from '@umax/roditeli-types/entity';
import { CreateUserDto, UpdateUserDto } from '@umax/roditeli-types/dto';
import UsersCRUD from '@/components/modals/crud/UsersCRUD.vue';

const users = namespace('Users');

@Component({
    components: { UsersCRUD },
})
export default class Users extends Vue {
    @users.State((state) => state.users)
    private readonly users!: User[];

    private crudModal = false;
    private columns = [
        {
            title: 'Имя',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Роли',
            dataIndex: 'roles',
            key: 'roles',
            scopedSlots: { customRender: 'roles' },
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            scopedSlots: { customRender: 'action' },
        },
    ];
    private currentId: string | null = null;

    @users.Action
    createUser!: (data: CreateUserDto) => void;
    @users.Action
    updateUser!: (data: { user: UpdateUserDto; id: string }) => void;
    @users.Action
    fetchUsers!: () => void;
    @users.Action
    removeUser!: (id: string) => void;

    get currentUser(): User | null {
        if (this.currentId) {
            return this.users.find((user) => user.id === this.currentId) || null;
        }
        return null;
    }

    async mounted(): Promise<void> {
        try {
            await this.fetchUsers();
        } catch (e: any) {
            console.log('error');
            this.$message.error('У вас недостаточно прав для этой страницы');
        }
    }

    private toggleModal() {
        if (this.crudModal) {
            this.currentId = null;
        }
        this.crudModal = !this.crudModal;
    }

    private async handleCreateUser(data: CreateUserDto) {
        try {
            await this.createUser(data);
            this.toggleModal();
            this.fetchUsers();
        } catch (e: any) {
            this.$message.error(e.message);
        }
    }

    private async handleUpdateUser(data: UpdateUserDto) {
        if (this.currentId) {
            try {
                await this.updateUser({
                    user: data,
                    id: this.currentId,
                });
                this.toggleModal();
                this.fetchUsers();
            } catch (e: any) {
                this.$message.error(e.message);
            }
        }
    }

    private async handleDeleteUser(id: string) {
        try {
            await this.removeUser(id);
            this.fetchUsers();
        } catch (e: any) {
            this.$message.error(e.message);
        }
    }

    private setCurrentId(id: string) {
        this.currentId = id;
    }

    @Watch('currentId')
    handleChange(val: string): void {
        if (val) {
            this.toggleModal();
        }
    }
}
