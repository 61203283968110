import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import { CreateVideoDto, UpdateVideoDto } from '@umax/roditeli-types/dto';
import { Video } from '@umax/roditeli-types/entity';

@Module({ namespaced: true })
export class Videos extends VuexModule {
    videos: Video[] = [];

    @Mutation
    setVideos(data: Video[]): void {
        this.videos = data;
    }

    @Action({ commit: 'setVideos', rawError: true })
    async fetchVideos(): Promise<void> {
        const videos = await axios.get('/admin/videos');
        return videos.data;
    }

    @Action({ rawError: true })
    async createVideo(data: CreateVideoDto): Promise<void> {
        return axios.post('/admin/videos', data);
    }

    @Action({ rawError: true })
    async updateVideo(data: { video: UpdateVideoDto; id: string }): Promise<void> {
        const { video, id } = data;
        return axios.patch('/admin/videos', video, {
            params: {
                id,
            },
        });
    }

    @Action({ rawError: true })
    async removeVideo(id: string): Promise<void> {
        return axios.delete(`/admin/videos/${id}`);
    }
}
