
import { Vue, Component, Emit, Ref, Prop } from 'vue-property-decorator';
import { pick } from 'lodash';
import { CreateAuthorDto, UpdateAuthorDto } from '@umax/roditeli-types/dto';
import { Author } from '@umax/roditeli-types/entity';
import FileUploader from '@/components/shared/FileUploader.vue';
import RichEditor from '@/components/shared/RichEditor.vue';
import SEODrawer from '@/components/modals/crud/SEODrawer.vue';
import { replaceScriptTemplate } from '@/utils/replaceScriptTemplate';

@Component({
    components: { FileUploader, RichEditor, SEODrawer },
})
export default class AuthorsCRUD extends Vue {
    @Ref('formInstance')
    readonly formInstance!: { validate: () => Promise<boolean> };

    @Prop({ default: null })
    private dataAuthor!: Author;

    data() {
        return {
            form: this.dataAuthor
                ? (pick(this.dataAuthor, [
                      'name',
                      'photo',
                      'description',
                      'biography',
                      'alias',
                      'seo',
                  ]) as UpdateAuthorDto)
                : ({
                      name: '',
                      description: '',
                      biography: '',
                      photo: '',
                      alias: '',
                      seo: {
                          image: '',
                          title: '',
                          description: '',
                      },
                  } as unknown as CreateAuthorDto),
        };
    }

    private rules = {
        name: [
            { required: true, message: 'Введите имя', trigger: 'blur' },
            {
                min: 3,
                message: 'Имя должно быть не менее 5 символов',
                trigger: 'blur',
            },
        ],
    };

    @Emit('cancel')
    handleCancel(): void {}

    @Emit('onOk')
    onOk(data: CreateAuthorDto): CreateAuthorDto {
        if (data.biography) data.biography = replaceScriptTemplate(data.biography);

        return data;
    }

    @Emit('onOk')
    onOkUpdate(data: UpdateAuthorDto): UpdateAuthorDto {
        return data;
    }

    private generateAlias(): void {
        this.$data.form.alias = this.translitText(this.$data.form.name as string);
    }

    async handleSubmit(): Promise<void> {
        const isValid = await (
            this.$refs.formInstance as unknown as {
                validate: () => Promise<boolean>;
            }
        ).validate();

        if (isValid) {
            if (this.dataAuthor) {
                this.onOkUpdate(this.$data.form);
            } else {
                this.onOk(this.$data.form as CreateAuthorDto);
            }
        } else {
            this.$message.error('Ошибка валидации');
        }
    }

    private seoDrawer = false;
}
