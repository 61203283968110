
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ru from 'ant-design-vue/lib/locale-provider/ru_RU';
import AuthModal from '@/components/modals/AuthModal.vue';
import { User } from '@umax/roditeli-types/entity';
import { LoginDto } from '@umax/roditeli-types/dto';

const auth = namespace('Auth');
const author = namespace('Authors');

@Component({
    components: {
        AuthModal,
    },
})
export default class App extends Vue {
    private locale = ru;
    private authModalVisible = false;

    @auth.State((state) => state.user) user!: User;

    @auth.Action
    public signIn!: (data: LoginDto) => void;

    @auth.Action
    public logOut!: () => void;

    @author.Action
    public fetchAuthors!: () => void;

    @Watch('user')
    userChanged(val: User): void {
        if (val) {
            this.authModalVisible = false;
            this.fetchAuthors();
        }
    }
}
