import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import axios from 'axios';
import { User } from '@umax/roditeli-types/entity';
import { LoginDto } from '@umax/roditeli-types/dto';

@Module({ namespaced: true })
export class Auth extends VuexModule {
    public user: User | null = null;
    public isLoginError = false;

    @Mutation
    public setUser(user: User): void {
        this.user = user;
    }

    @Mutation
    public setIsLoginError(data: boolean): void {
        this.isLoginError = data;
    }

    @Action({ commit: 'setUser', rawError: true })
    public async getProfile(): Promise<User> {
        const { data } = await axios.get<User>('/profile');
        return data;
    }

    @Action({ rawError: true })
    public async signIn(data: LoginDto): Promise<void> {
        try {
            await axios.post('/auth/login', data);
            await this.context.dispatch('getProfile');
            this.context.commit('setIsLoginError', false);
        } catch (e: any) {
            this.context.commit('setIsLoginError', true);
        }
    }

    @Action({ commit: 'setUser', rawError: true })
    public async logOut(): Promise<null> {
        axios.post('/auth/logout');
        return null;
    }
}
