import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import { CreatePodcastDto, UpdatePodcastDto } from '@umax/roditeli-types/dto';
import { Podcast } from '@umax/roditeli-types/entity';

@Module({ namespaced: true })
export class Podcasts extends VuexModule {
    podcasts: Podcast[] = [];

    @Mutation
    setPodcasts(data: Podcast[]): void {
        this.podcasts = data;
    }

    @Action({ commit: 'setPodcasts', rawError: true })
    async fetchPodcasts(): Promise<void> {
        const podcasts = await axios.get('/admin/podcasts');
        return podcasts.data;
    }

    @Action({ rawError: true })
    async createPodcast(data: CreatePodcastDto): Promise<void> {
        return axios.post('/admin/podcasts', data);
    }

    @Action({ rawError: true })
    async updatePodcast(data: { podcast: UpdatePodcastDto; id: string }): Promise<void> {
        const { podcast, id } = data;
        return axios.patch('/admin/podcasts', podcast, {
            params: {
                id,
            },
        });
    }

    @Action({ rawError: true })
    async removePodcast(id: string): Promise<void> {
        return axios.delete(`/admin/podcasts/${id}`);
    }
}
