import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';

@Module({ namespaced: true })
export class Hashtags extends VuexModule {
    private hashtags: string[] = [];

    @Mutation
    setTags(tags: string[]): void {
        this.hashtags = tags;
    }

    @Action({ commit: 'setTags', rawError: true })
    async fetchTags(): Promise<void> {
        const { data } = await axios.get('/admin/hashtags');
        return data;
    }
}
