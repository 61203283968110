
import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from 'date-fns/format';
import CardsCRUD from '@/components/modals/crud/CardsCRUD.vue';
import { CreateCardDto, UpdateCardDto } from '@umax/roditeli-types/dto';
import { Card } from '@umax/roditeli-types/entity';

const cards = namespace('Cards');

@Component({
    components: { CardsCRUD },
})
export default class Cards extends Vue {
    @cards.State((state) => state.cards)
    private readonly cards!: Card[];

    private crudModal = false;
    private columns = [
        {
            title: 'Название',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Автор',
            dataIndex: 'author',
            key: 'author',
            scopedSlots: { customRender: 'author' },
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '12%',
            scopedSlots: { customRender: 'createdAt' },
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: '12%',
            scopedSlots: { customRender: 'updatedAt' },
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            scopedSlots: { customRender: 'action' },
        },
    ];
    private currentCardId: string | null = null;

    @cards.Action
    createCard!: (data: CreateCardDto) => void;
    @cards.Action
    updateCard!: (data: { card: UpdateCardDto; id: string }) => void;
    @cards.Action
    fetchCards!: () => void;
    @cards.Action
    removeCard!: (id: string) => void;

    get currentCard(): Card | null {
        if (this.currentCardId) {
            return this.cards.find((card) => card.id === this.currentCardId) || null;
        }
        return null;
    }

    async mounted(): Promise<void> {
        try {
            await this.fetchCards();
        } catch (e: any) {
            this.$message.error(e.message);
        }
    }

    private toggleModal() {
        if (this.crudModal) {
            this.currentCardId = null;
        }
        this.crudModal = !this.crudModal;
    }

    private async handleCreateCard(data: CreateCardDto) {
        try {
            await this.createCard(data);
            this.toggleModal();
            this.fetchCards();
        } catch (e: any) {
            this.$message.error(e.message);
        }
    }

    private async handleUpdateCard(data: UpdateCardDto) {
        if (this.currentCardId) {
            try {
                await this.updateCard({
                    card: data,
                    id: this.currentCardId,
                });
                this.toggleModal();
                this.fetchCards();
            } catch (e: any) {
                this.$message.error(e.message);
            }
        }
    }

    private async handleDeleteCard(id: string) {
        try {
            await this.removeCard(id);
            this.fetchCards();
        } catch (e: any) {
            this.$message.error(e.message);
        }
    }

    private formatDate(date: string): string {
        return format(new Date(date), 'dd.MM.yy HH:mm');
    }

    private setCurrentCardId(id: string) {
        this.currentCardId = id;
    }

    @Watch('currentCardId')
    handleChange(val: string): void {
        if (val) {
            this.toggleModal();
        }
    }
}
