import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import { Banner } from '../types/BannerTypes';

@Module({ namespaced: true })
export class Banners extends VuexModule {
    banners: Banner[] = [];

    get getBanners(): Banner[] {
        return this.banners;
    }

    @Mutation
    setBanners(data: Banner[]): void {
        this.banners = data;
    }

    @Action({ commit: 'setBanners', rawError: true })
    async fetchBanners(): Promise<void> {
        try {
            const { data } = await axios.get('/banners');

            if (data) {
                return data;
            }
        } catch (e: any) {
            console.log(e);
        }
    }

    @Action({ rawError: true })
    async createBanner(data: Banner): Promise<void> {
        try {
            return await axios.post('/banners', data);
        } catch (e: any) {
            console.error(e);
        }
    }

    @Action({ rawError: true })
    async updateBanner(data: Banner): Promise<void> {
        const { id } = data;

        try {
            return await axios.patch(`/banners/${id}`, data);
        } catch (e: any) {
            console.error(e);
        }
    }

    @Action({ rawError: true })
    async deleteBanner(id: string): Promise<void> {
        try {
            return await axios.delete(`/banners/${id}`);
        } catch (e: any) {
            console.error(e);
        }
    }
}
