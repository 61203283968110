
import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Pdf } from '@umax/roditeli-types/entity';
import { CreatePdfDto, UpdatePdfDto } from '@umax/roditeli-types/dto';
import PdfsCRUD from '@/components/modals/crud/PdfsCRUD.vue';

const pdfs = namespace('Pdfs');

@Component({
    components: { PdfsCRUD },
})
export default class Pdfs extends Vue {
    @pdfs.State((state) => state.pdfs)
    private readonly pdfs!: Pdf[];

    private crudModal = false;
    private columns = [
        {
            title: 'Название',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Пользовательская ссылка',
            dataIndex: 'source',
            key: 'source',
        },
        {
            title: 'Оригинальная ссылка',
            dataIndex: 'url',
            key: 'url',
        },
        {
            title: 'Автор',
            dataIndex: 'author',
            key: 'author',
            scopedSlots: { customRender: 'author' },
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            scopedSlots: { customRender: 'action' },
        },
    ];
    private currentId: string | null = null;

    @pdfs.Action
    createPdf!: (data: CreatePdfDto) => void;
    @pdfs.Action
    updatePdf!: (data: { pdf: UpdatePdfDto; id: string }) => void;
    @pdfs.Action
    fetchPdfs!: () => void;
    @pdfs.Action
    removePdf!: (id: string) => void;

    get currentPdf(): Pdf | null {
        if (this.currentId) {
            return this.pdfs.find((pdf) => pdf.id === this.currentId) || null;
        }
        return null;
    }

    async mounted(): Promise<void> {
        try {
            await this.fetchPdfs();
        } catch (e: any) {
            this.$message.error('У вас недостаточно прав для этой страницы');
        }
    }

    private toggleModal() {
        if (this.crudModal) {
            this.currentId = null;
        }
        this.crudModal = !this.crudModal;
    }

    private async handleCreatePdf(data: CreatePdfDto) {
        try {
            await this.createPdf(data);
            this.toggleModal();
            this.fetchPdfs();
        } catch (e: any) {
            this.$message.error(e.message);
        }
    }

    private async handleUpdatePdf(data: UpdatePdfDto) {
        if (this.currentId) {
            try {
                await this.updatePdf({
                    pdf: data,
                    id: this.currentId,
                });
                this.toggleModal();
                this.fetchPdfs();
            } catch (e: any) {
                this.$message.error(e.message);
            }
        }
    }

    private async handleDeletePdf(id: string) {
        try {
            await this.removePdf(id);
            this.fetchPdfs();
        } catch (e: any) {
            this.$message.error(e.message);
        }
    }

    private setCurrentId(id: string) {
        this.currentId = id;
    }

    @Watch('currentId')
    handleChange(val: string): void {
        if (val) {
            this.toggleModal();
        }
    }
}
