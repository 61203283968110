import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import { IStats } from '@/@types';

@Module({ namespaced: true })
export class Stats extends VuexModule {
    private stats: IStats = {} as IStats;

    @Mutation
    setStats(stats: IStats): void {
        this.stats = stats;
    }

    @Action({ commit: 'setStats', rawError: true })
    async fetchStats(): Promise<void> {
        const { data } = await axios.get('/stats');
        return data;
    }
}
