
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import axios from 'axios';

@Component({})
export default class RichEditor extends Vue {
    @Ref('imageInput')
    imageInput!: HTMLInputElement;

    @Prop({ default: '' })
    private value!: string;
    private content = '';

    mounted(): void {
        setTimeout(() => {
            this.content = this.value;
        }, 0);

        this.changeEditTooltip();
    }

    private onEditorChange({ html }: { html: string }): void {
        this.handleChange(html);
    }

    private editorOption = {
        modules: {
            toolbar: {
                container: [
                    [{ font: [] }, { size: ['small', false, 'large', 'huge'] }],

                    ['bold', 'italic', 'underline', 'strike'],

                    [{ color: [] }, { background: [] }],

                    [{ script: 'sub' }, { script: 'super' }],

                    [
                        { header: 1 },
                        { header: 2 },
                        { header: 3 },
                        'blockquote',
                        'code-block',
                    ],

                    [
                        { list: 'ordered' },
                        { list: 'bullet' },
                        { indent: '-1' },
                        { indent: '+1' },
                    ],

                    [{ direction: 'rtl' }, { align: [] }],

                    ['image', 'video', 'formula', 'link', 'addScript'],

                    ['clean'],
                ],
                handlers: {
                    image: this.insertImage,
                    link: this.insertLink,
                    addScript: this.insertScript,
                },
            },
        },
    };

    get quillInstance(): any {
        return (this.$refs.quillEditor as any).quill;
    }

    @Emit('input')
    private handleChange(data: string): string {
        return data;
    }

    insertImage(): void {
        (this.$refs.imageInput as HTMLInputElement).click();
    }

    private changeEditTooltip(): void {
        const tooltip = this.quillInstance.theme.tooltip;

        const show = tooltip.show;
        tooltip.show = function () {
            show.apply(this);

            const tooltip: any = document?.querySelector('.ql-tooltip');
            const preview: any = document?.querySelector('.ql-preview');

            const href: any = document.querySelectorAll(
                '[href="' + preview.getAttribute('href') + '"]',
            );

            if (href.length !== 2) {
                tooltip.setAttribute('data-content', 'Ссылка:');
                return;
            }

            if (href.length === 2) {
                const rel = href[0].getAttribute('rel');

                if (rel.includes('follow') && !rel.includes('nofollow')) {
                    tooltip.setAttribute('data-content', 'Ссылка (follow):');
                    return;
                }

                if (rel.includes('nofollow')) {
                    tooltip.setAttribute('data-content', 'Ссылка (nofollow):');
                    return;
                }

                tooltip.setAttribute('data-content', 'Ссылка (неопределено):');
            }
        };
    }

    private static changeLinkTooltip(): void {
        const tooltip: any = document?.querySelector('.ql-tooltip');
        const radio = document.createElement('div');
        radio.classList.add('rel-radio-types');

        const follow =
            '<div>' +
            '<input type="radio" id="rel-follow" name="rel-type" value="follow"> ' +
            '<label for="rel-follow">follow</label>' +
            '<div>';

        const nofollow =
            '<div>' +
            '<input type="radio" id="rel-nofollow" name="rel-type" value="nofollow" checked> ' +
            '<label for="rel-nofollow">nofollow</label> ' +
            '</div>';

        radio.innerHTML += follow;
        radio.innerHTML += nofollow;

        tooltip.appendChild(radio);
    }

    private insertLink(): void {
        RichEditor.changeLinkTooltip();
        const tooltip = this.quillInstance.theme.tooltip;

        tooltip.save = function () {
            const select: any = document?.querySelector('input[name="rel-type"]:checked');

            this.quill.format('link', {
                value: this.textbox?.value,
                type: select?.value,
            });
        };

        const hide = tooltip.hide;
        tooltip.hide = function () {
            const radio: any = document?.querySelector('.rel-radio-types');
            if (radio) radio.remove();
            hide.apply(this);
        };

        tooltip.edit('link', '');
    }

    private insertScript(): void {
        const selectedContent = this.quillInstance.getSelection();
        const selectedHtml = this.quillInstance
            .getContents(selectedContent.index, selectedContent.length)
            .ops.map((op: any) => op.insert)
            .join('');

        if (!selectedContent.index)
            this.quillInstance.insertText(selectedContent.index, '\n');

        this.quillInstance.format('addScript', selectedHtml);

        this.quillInstance.insertText(
            selectedContent.index + selectedContent.length + 1,
            '\n',
        );

        this.quillInstance.setSelection(selectedContent.index + 1, 0);
    }

    async doImageUpload(
        event: InputEvent & { target: { value: string; files: FileList } },
    ): Promise<void> {
        const files = event.target.files;
        const file = files[0];
        const fd = new FormData();
        fd.append('file', file);
        fd.append('path', 'podcasts/images');
        fd.append('size', `${file.size}`);
        fd.append('mimeType', file.type);
        fd.append('name', file.name);
        const response = await axios.post('/storage', fd);
        event.target.value = '';
        const currentIndex = this.quillInstance.selection.lastRange.index;
        this.quillInstance.insertEmbed(
            currentIndex,
            'image',
            this.getFileUrl(response.data),
        );
        this.quillInstance.setSelection(currentIndex + 1, 0);
    }
}
