
import { Vue, Component, Prop, Emit, Ref } from 'vue-property-decorator';
import type { SEO as SEOType } from '@umax/roditeli-types/interfaces';
import FileUploader from '@/components/shared/FileUploader.vue';

@Component({
    components: { FileUploader },
})
export default class SEODrawer extends Vue {
    @Ref('formInstance')
    readonly formInstance!: { validate: () => Promise<boolean>; resetFields: () => void };

    @Prop({ default: { title: '', description: '', image: '' } })
    value!: SEOType;

    @Prop({ default: false })
    visible!: boolean;

    @Emit('input')
    onOk(): SEOType {
        this.handleClose();
        return this.value;
    }

    @Emit('close')
    handleClose(): void {
        return;
    }
}
