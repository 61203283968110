
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from 'date-fns/format';
import { Subscriber } from '@umax/roditeli-types/entity';

const subscribes = namespace('Subscribes');

@Component({
    components: {},
})
export default class Subscribes extends Vue {
    @subscribes.State((state) => state.subscribes)
    private readonly subscribes!: Subscriber[];

    private columns = [
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Подтвержден',
            dataIndex: 'isEmailConfirmed',
            key: 'isEmailConfirmed',
            scopedSlots: { customRender: 'isEmailConfirmed' },
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '12%',
            scopedSlots: { customRender: 'createdAt' },
        },
    ];

    @subscribes.Action
    fetchSubscribes!: () => void;

    async mounted(): Promise<void> {
        try {
            await this.fetchSubscribes();
        } catch (e: any) {
            this.$message.error('У вас недостаточно прав для этой страницы');
        }
    }

    private formatDate(date: string): string {
        return format(new Date(date), 'dd.MM.yy HH:mm');
    }

    private downloadReport(): void {
        window.location.assign('/api/v1/admin/subscribe/report');
    }
}
