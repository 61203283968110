
import { Component, Emit, Prop, Ref, Vue } from 'vue-property-decorator';
import { pick } from 'lodash';
import { CreateUserDto, UpdateUserDto } from '@umax/roditeli-types/dto';
import { User } from '@umax/roditeli-types/entity';
import FileUploader from '@/components/shared/FileUploader.vue';
import { Role } from '@umax/roditeli-types/enums';

@Component({
    components: { FileUploader },
})
export default class UsersCRUD extends Vue {
    @Ref('formInstance')
    readonly formInstance!: { validate: () => Promise<boolean> };

    @Prop({ default: null })
    private dataUser!: User;

    private roles = Object.entries(Role).filter((role) => role[1] !== 'admin');

    data() {
        return {
            rules: {},
            form: this.dataUser
                ? {
                      ...(pick(this.dataUser, [
                          'name',
                          'email',
                          'roles',
                      ]) as UpdateUserDto),
                      password: '',
                  }
                : ({
                      name: '',
                      email: '',
                      roles: [Role.User],
                      password: '',
                  } as CreateUserDto),
        };
    }

    @Emit('cancel')
    handleCancel(): void {}

    @Emit('onOk')
    onOk(data: CreateUserDto): CreateUserDto {
        return data;
    }

    @Emit('onOk')
    onOkUpdate(data: UpdateUserDto): UpdateUserDto {
        return data;
    }

    mounted(): void {
        this.makeRules();
    }

    async handleSubmit(): Promise<void> {
        const isValid = await (
            this.$refs.formInstance as unknown as {
                validate: () => Promise<boolean>;
            }
        ).validate();

        if (isValid) {
            if (this.dataUser) {
                this.onOkUpdate(this.$data.form);
            } else {
                this.onOk(this.$data.form as CreateUserDto);
            }
        } else {
            this.$message.error('Ошибка валидации');
        }
    }

    private makeRules(): void {
        this.$data.rules = {
            name: [
                { required: true, message: 'Введите имя', trigger: 'blur' },
                {
                    min: 3,
                    message: 'Имя должно быть не менее 3 символов',
                    trigger: 'blur',
                },
            ],
            email: [
                { required: true, message: 'Введите email', trigger: 'blur' },
                { type: 'email', message: 'Введите валидный email', trigger: 'blur' },
            ],
        };

        if (!this.dataUser) {
            this.$data.rules.password = [
                { required: !this.dataUser, message: 'Введите пароль', trigger: 'blur' },
                {
                    min: 8,
                    message: 'Пароль должен быть не менее 8 символов',
                    trigger: 'blur',
                },
            ];
        }
    }
}
