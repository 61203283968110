
import { Vue, Component, Emit, Ref, Prop } from 'vue-property-decorator';
import { pick } from 'lodash';
import RichEditor from '@/components/shared/RichEditor.vue';
import { Podcast } from '@umax/roditeli-types/entity';
import FileUploader from '@/components/shared/FileUploader.vue';
import { CreatePodcastDto, UpdatePodcastDto } from '@umax/roditeli-types/dto';
import AuthorSelector from '@/components/shared/AuthorSelector.vue';
import SEODrawer from '@/components/modals/crud/SEODrawer.vue';
import { namespace } from 'vuex-class';
import { replaceScriptTemplate } from '@/utils/replaceScriptTemplate';

const hashtagsStore = namespace('Hashtags');

@Component({
    components: { SEODrawer, AuthorSelector, FileUploader, RichEditor },
})
export default class PodcastsCRUD extends Vue {
    @Ref('formInstance')
    readonly formInstance!: { validate: () => Promise<boolean> };

    @Prop({ default: false })
    visible!: boolean;

    @Prop({ default: null })
    private dataPodcast!: Podcast;

    @hashtagsStore.State((state) => state.hashtags)
    hashtags!: string[];
    nameSubtitles?: string;

    data() {
        return {
            form: this.dataPodcast
                ? {
                      ...(pick(this.dataPodcast, [
                          'title',
                          'description',
                          'url',
                          'image',
                          'visible',
                          'authorId',
                          'seo',
                          'alias',
                          'illustrator',
                          'isMain',
                          'nameSubtitles',
                          'subtitles',
                          'imageAlt',
                          'imageTitle',
                      ]) as UpdatePodcastDto),
                      hashtags: this.dataPodcast.hashtags || [],
                  }
                : ({
                      title: '',
                      description: '',
                      url: '',
                      image: '',
                      visible: false,
                      authorId: '',
                      hashtags: [],
                      seo: {
                          image: '',
                          title: '',
                          description: '',
                      },
                      alias: '',
                      illustrator: '',
                      isMain: false,
                      nameSubtitles: '',
                      subtitles: [],
                      imageAlt: '',
                      imageTitle: '',
                  } as CreatePodcastDto),
        };
    }

    private rules = {
        title: [
            { required: true, message: 'Введите заголовок', trigger: 'blur' },
            {
                min: 10,
                message: 'Заголовое должен быть не менее 10 символов',
                trigger: 'blur',
            },
        ],
        url: [{ type: 'url', message: 'Введите валидную ссылку', trigger: 'blur' }],
        description: [
            { required: true, message: 'Введите описание', trigger: 'blur' },
            {
                min: 10,
                message: 'Описание должен быть не менее 10 символов',
                trigger: 'blur',
            },
        ],
    };

    private seoDrawer = false;

    @Emit('cancel')
    handleCancel(): void {}

    @Emit('onOk')
    onOk(data: CreatePodcastDto): CreatePodcastDto {
        if (data.description) data.description = replaceScriptTemplate(data.description);

        return data;
    }

    @Emit('onOk')
    onOkUpdate(data: UpdatePodcastDto): UpdatePodcastDto {
        if (data.description) data.description = replaceScriptTemplate(data.description);

        return data;
    }

    @hashtagsStore.Action
    fetchTags!: () => void;

    mounted(): void {
        this.fetchTags();
    }

    get isAudio(): boolean {
        if (!this.$data.form.url) {
            return false;
        }
        return this.$data.form.url.endsWith('.mp3');
    }

    async handleSubmit(): Promise<void> {
        const isValid = await (
            this.$refs.formInstance as unknown as {
                validate: () => Promise<boolean>;
            }
        ).validate();

        if (isValid) {
            if (this.dataPodcast) {
                this.onOkUpdate(this.$data.form);
            } else {
                this.onOk(this.$data.form as CreatePodcastDto);
            }
        } else {
            this.$message.error('Ошибка валидации');
        }
    }

    private handleVisibleChange(visible: boolean): void {
        if (!visible) {
            // @ts-ignore
            this.$refs.formInstance.resetFields();
            this.$data.form.image = '';
            this.$data.form.seo = {
                image: '',
                title: '',
                description: '',
            };
        }
    }

    private generateAlias(): void {
        this.$data.form.alias = this.translitText(this.$data.form.title as string);
    }

    changeSubtitles(e: { target: { files: File[] } }): void {
        const fileList = e.target.files;
        this.$data.form.nameSubtitles = e.target.files[0].name;

        const fr = new FileReader();
        fr.onload = () => {
            this.$data.form.subtitles = (fr.result as string)
                .split(/[\r\n]+/g)
                .map((line: string) => line.trim())
                .map((line: string) => {
                    const [time, ...words] = line.split(' ');
                    return {
                        timestamp: Number(time),
                        words: words.join(' '),
                    };
                });
        };

        if (fileList && fileList.length) {
            fr.readAsText(fileList[0]);
        } else {
            this.$data.form.subtitles = [];
        }
    }
}
