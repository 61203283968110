import { Component, Vue } from 'vue-property-decorator';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
const cyrillicToTranslit = new (CyrillicToTranslit as any)();

@Component
export default class Mixin extends Vue {
    getFileUrl(path: string): string {
        const { VUE_APP_S3_HOST, VUE_APP_S3_BUCKET } = process.env;
        return `${VUE_APP_S3_HOST}/${VUE_APP_S3_BUCKET}/${path}`;
    }

    translitText(text: string): string {
        return cyrillicToTranslit
            .transform(text, '-')
            .toLowerCase()
            .replace(/[^A-Za-z0-9\s-]/g, '');
    }

    getContentUrl(
        content: 'texts' | 'podcasts' | 'videos' | 'cards',
        alias: string,
    ): string {
        const { VUE_APP_SITE } = process.env;
        return `${VUE_APP_SITE}/${content}/${alias}`;
    }
}
