
import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from 'date-fns/format';
import TextsCRUD from '@/components/modals/crud/TextsCRUD.vue';
import { CreateTextDto, UpdateTextDto } from '@umax/roditeli-types/dto';
import { Text } from '@umax/roditeli-types/entity';

const texts = namespace('Texts');

@Component({
    components: { TextsCRUD },
})
export default class Texts extends Vue {
    @texts.State((state) => state.texts)
    private readonly texts!: Text[];

    private crudModal = false;
    private columns = [
        {
            title: 'Название',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Автор',
            dataIndex: 'author',
            key: 'author',
            scopedSlots: { customRender: 'author' },
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '12%',
            scopedSlots: { customRender: 'createdAt' },
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: '12%',
            scopedSlots: { customRender: 'updatedAt' },
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            scopedSlots: { customRender: 'action' },
        },
    ];
    private currentTextId: string | null = null;

    @texts.Action
    createText!: (data: CreateTextDto) => void;
    @texts.Action
    updateText!: (data: { text: UpdateTextDto; id: string }) => void;
    @texts.Action
    fetchTexts!: () => void;
    @texts.Action
    removeText!: (id: string) => void;

    get currentText(): Text | null {
        if (this.currentTextId) {
            return this.texts.find((text) => text.id === this.currentTextId) || null;
        }
        return null;
    }

    async mounted(): Promise<void> {
        try {
            await this.fetchTexts();
        } catch (e: any) {
            this.$message.error('У вас недостаточно прав для этой страницы');
        }
    }

    private toggleModal() {
        if (this.crudModal) {
            this.currentTextId = null;
        }
        this.crudModal = !this.crudModal;
    }

    private async handleCreateText(data: CreateTextDto) {
        try {
            await this.createText(data);
            this.toggleModal();
            this.fetchTexts();
        } catch (e: any) {
            this.$message.error(e.message);
        }
    }

    private async handleUpdateText(data: UpdateTextDto) {
        if (this.currentTextId) {
            try {
                await this.updateText({
                    text: data,
                    id: this.currentTextId,
                });
                this.toggleModal();
                this.fetchTexts();
            } catch (e: any) {
                this.$message.error(e.message);
            }
        }
    }

    private async handleDeleteText(id: string) {
        try {
            await this.removeText(id);
            this.fetchTexts();
        } catch (e: any) {
            this.$message.error(e.message);
        }
    }

    private formatDate(date: string): string {
        return format(new Date(date), 'dd.MM.yy HH:mm');
    }

    private setCurrentTextId(id: string) {
        this.currentTextId = id;
    }

    @Watch('currentTextId')
    handleChange(val: string): void {
        if (val) {
            this.toggleModal();
        }
    }
}
