
import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from 'date-fns/format';
import VideosCRUD from '@/components/modals/crud/VideosCRUD.vue';
import { CreateVideoDto, UpdateVideoDto } from '@umax/roditeli-types/dto';
import { Video } from '@umax/roditeli-types/entity';

const videos = namespace('Videos');

@Component({
    components: { VideosCRUD },
})
export default class Videos extends Vue {
    @videos.State((state) => state.videos)
    private readonly videos!: Video[];

    private crudModal = false;
    private columns = [
        {
            title: 'Название',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Автор',
            dataIndex: 'author',
            key: 'author',
            scopedSlots: { customRender: 'author' },
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '12%',
            scopedSlots: { customRender: 'createdAt' },
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: '12%',
            scopedSlots: { customRender: 'updatedAt' },
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            scopedSlots: { customRender: 'action' },
        },
    ];
    private currentId: string | null = null;

    @videos.Action
    createVideo!: (data: CreateVideoDto) => Promise<void>;
    @videos.Action
    updateVideo!: (data: { video: UpdateVideoDto; id: string }) => void;
    @videos.Action
    fetchVideos!: () => void;
    @videos.Action
    removeVideo!: (id: string) => void;

    get currentVideo(): Video | null {
        if (this.currentId) {
            return this.videos.find((video) => video.id === this.currentId) || null;
        }
        return null;
    }

    async mounted(): Promise<void> {
        try {
            await this.fetchVideos();
        } catch (e: any) {
            this.$message.error('У вас недостаточно прав для этой страницы');
        }
    }

    private toggleModal() {
        if (this.crudModal) {
            this.currentId = null;
        }
        this.crudModal = !this.crudModal;
    }

    private async handleCreate(data: CreateVideoDto) {
        try {
            await this.createVideo(data);
            this.toggleModal();
            this.fetchVideos();
        } catch (e: any) {
            this.$message.error(e.message);
        }
    }

    private async handleUpdate(data: UpdateVideoDto) {
        if (this.currentId) {
            try {
                await this.updateVideo({
                    video: data,
                    id: this.currentId,
                });
                this.toggleModal();
                this.fetchVideos();
            } catch (e: any) {
                this.$message.error(e.message);
            }
        }
    }

    private async handleDelete(id: string) {
        try {
            await this.removeVideo(id);
            this.fetchVideos();
        } catch (e: any) {
            this.$message.error(e.message);
        }
    }

    private formatDate(date: string): string {
        return format(new Date(date), 'dd.MM.yy HH:mm');
    }

    private setCurrentId(id: string) {
        this.currentId = id;
    }

    @Watch('currentId')
    handleChange(val: string): void {
        if (val) {
            this.toggleModal();
        }
    }
}
