
import { Vue, Component, Emit, Ref, Prop } from 'vue-property-decorator';
import { pick } from 'lodash';
import { CreatePdfDto, UpdatePdfDto } from '@umax/roditeli-types/dto';
import { Pdf } from '@umax/roditeli-types/entity';
import FileUploader from '@/components/shared/FileUploader.vue';
import SEODrawer from '@/components/modals/crud/SEODrawer.vue';
import AuthorSelector from '@/components/shared/AuthorSelector.vue';

@Component({
    components: { FileUploader, AuthorSelector, SEODrawer },
})
export default class PdfsCRUD extends Vue {
    @Ref('formInstance')
    readonly formInstance!: { validate: () => Promise<boolean> };

    @Prop({ default: null })
    private dataPdf!: Pdf;

    data() {
        return {
            form: this.dataPdf
                ? (pick(this.dataPdf, [
                      'title',
                      'description',
                      'authorId',
                      'isMain',
                      'url',
                      'alias',
                  ]) as UpdatePdfDto)
                : ({
                      title: '',
                      description: '',
                      authorId: '',
                      url: null,
                      alias: '',
                      isMain: false,
                  } as unknown as CreatePdfDto),
        };
    }

    private rules = {
        title: [
            { required: true, message: 'Введите название', trigger: 'blur' },
            {
                min: 10,
                message: 'Заголовое должен быть не менее 10 символов',
                trigger: 'blur',
            },
        ],
        description: [
            { required: false, message: 'Введите описание', trigger: 'blur' },
            {
                min: 10,
                message: 'Описание должно быть не менее 10 символов',
                trigger: 'blur',
            },
        ],
        authorId: [{ required: true, message: 'Выберите автора', trigger: 'blur' }],
        url: [{ required: true, message: 'Выберите файл', trigger: 'blur' }],
    };

    @Emit('cancel')
    handleCancel(): void {}

    @Emit('onOk')
    onOk(data: CreatePdfDto): CreatePdfDto {
        return data;
    }

    @Emit('onOk')
    onOkUpdate(data: UpdatePdfDto): UpdatePdfDto {
        return data;
    }

    async handleSubmit(): Promise<void> {
        const isValid = await (
            this.$refs.formInstance as unknown as {
                validate: () => Promise<boolean>;
            }
        ).validate();

        if (isValid) {
            if (this.dataPdf) {
                this.onOkUpdate(this.$data.form);
            } else {
                this.onOk(this.$data.form as CreatePdfDto);
            }
        } else {
            this.$message.error('Ошибка валидации');
        }
    }
}
