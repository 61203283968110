
import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IStats } from '@/@types';

const stats = namespace('Stats');

@Component({})
export default class Home extends Vue {
    @stats.State((state) => state.stats)
    private data!: IStats;

    @stats.Action
    fetchStats!: () => void;

    async mounted(): Promise<void> {
        try {
            this.fetchStats();
        } catch (e: any) {
            this.$message.error(e.message);
        }
    }
}
