
import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from 'date-fns/format';
import PodcastsCRUD from '@/components/modals/crud/PodcastsCRUD.vue';
import { CreatePodcastDto, UpdatePodcastDto } from '@umax/roditeli-types/dto';
import { Podcast } from '@umax/roditeli-types/entity';

const podcasts = namespace('Podcasts');

@Component({
    components: { PodcastsCRUD },
})
export default class Podcasts extends Vue {
    @podcasts.State((state) => state.podcasts)
    private readonly podcasts!: Podcast[];

    private crudModal = false;
    private columns = [
        {
            title: 'Название',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Автор',
            dataIndex: 'author',
            key: 'author',
            scopedSlots: { customRender: 'author' },
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '12%',
            scopedSlots: { customRender: 'createdAt' },
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: '12%',
            scopedSlots: { customRender: 'updatedAt' },
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            scopedSlots: { customRender: 'action' },
        },
    ];
    private currentPodcastId: string | null = null;

    @podcasts.Action
    createPodcast!: (data: CreatePodcastDto) => void;
    @podcasts.Action
    updatePodcast!: (data: { podcast: UpdatePodcastDto; id: string }) => void;
    @podcasts.Action
    fetchPodcasts!: () => void;
    @podcasts.Action
    removePodcast!: (id: string) => void;

    get currentPodcast(): Podcast | null {
        if (this.currentPodcastId) {
            return (
                this.podcasts.find((podcast) => podcast.id === this.currentPodcastId) ||
                null
            );
        }
        return null;
    }

    async mounted(): Promise<void> {
        try {
            this.fetchPodcasts();
        } catch (e: any) {
            this.$message.error('У вас недостаточно прав для этой страницы');
        }
    }

    private toggleModal() {
        if (this.crudModal) {
            this.currentPodcastId = null;
        }
        this.crudModal = !this.crudModal;
    }

    private async handleCreatePodcast(data: CreatePodcastDto) {
        try {
            await this.createPodcast(data);
            this.toggleModal();
            this.fetchPodcasts();
        } catch (e: any) {
            this.$message.error(e.message);
        }
    }

    private async handleUpdatePodcast(data: UpdatePodcastDto) {
        if (this.currentPodcastId) {
            try {
                await this.updatePodcast({
                    podcast: data,
                    id: this.currentPodcastId,
                });
                this.toggleModal();
                this.fetchPodcasts();
            } catch (e: any) {
                this.$message.error(e.message);
            }
        }
    }

    private async handleDeletePodcast(id: string) {
        try {
            await this.removePodcast(id);
            this.fetchPodcasts();
        } catch (e: any) {
            this.$message.error(e.message);
        }
    }

    private formatDate(date: string): string {
        return format(new Date(date), 'dd.MM.yy HH:mm');
    }

    private setCurrentPodcastId(id: string) {
        this.currentPodcastId = id;
    }

    @Watch('currentPodcastId')
    handleChange(val: string): void {
        if (val) {
            this.toggleModal();
        }
    }
}
