import Vue from 'vue';
import Vuex from 'vuex';
import { Auth } from '@/store/modules/auth';
import { Stats } from '@/store/modules/stats';
import { Podcasts } from '@/store/modules/podcasts';
import { Texts } from '@/store/modules/texts';
import { Videos } from '@/store/modules/videos';
import { Authors } from './modules/author';
import { Users } from '@/store/modules/users';
import { Cards } from '@/store/modules/cards';
import { Subscribes } from '@/store/modules/subscribes';
import { Hashtags } from '@/store/modules/hashtags';
import { Pdfs } from '@/store/modules/pdfs';
import { Banners } from '@/store/modules/banners';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        Auth,
        Stats,
        Podcasts,
        Texts,
        Videos,
        Authors,
        Users,
        Cards,
        Hashtags,
        Subscribes,
        Pdfs,
        Banners,
    },
});
