import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import { CreateCardDto, UpdateCardDto } from '@umax/roditeli-types/dto';
import { Card } from '@umax/roditeli-types/entity';

@Module({ namespaced: true })
export class Cards extends VuexModule {
    cards: Card[] = [];

    @Mutation
    setCards(data: Card[]): void {
        this.cards = data;
    }

    @Action({ commit: 'setCards', rawError: true })
    async fetchCards(): Promise<void> {
        const cards = await axios.get('/admin/cards');
        return cards.data;
    }

    @Action({ rawError: true })
    async createCard(data: CreateCardDto): Promise<void> {
        return axios.post('/admin/cards', data);
    }

    @Action({ rawError: true })
    async updateCard(data: { card: UpdateCardDto; id: string }): Promise<void> {
        const { card, id } = data;
        return axios.patch('/admin/cards', card, {
            params: {
                id,
            },
        });
    }

    @Action({ rawError: true })
    async removeCard(id: string): Promise<void> {
        return axios.delete(`/admin/cards/${id}`);
    }
}
