import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import { Subscriber } from '@umax/roditeli-types/entity';

@Module({ namespaced: true })
export class Subscribes extends VuexModule {
    subscribes: Subscriber[] = [];

    @Mutation
    setSubscribes(data: Subscriber[]): void {
        this.subscribes = data;
    }

    @Action({ commit: 'setSubscribes', rawError: true })
    async fetchSubscribes(): Promise<void> {
        const { data } = await axios.get('/admin/subscribe');
        return data;
    }
}
