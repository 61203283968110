import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import Users from '@/views/Users.vue';
import Podcasts from '@/views/Podcasts.vue';
import Texts from '@/views/Texts.vue';
import Videos from '@/views/Videos.vue';
import Authors from '@/views/Authors.vue';
import Cards from '@/views/Cards.vue';
import Subscribes from '@/views/Subscribes.vue';
import Pdfs from '@/views/Pdfs.vue';
import Banners from '@/views/Banners.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
    },
    {
        path: '/podcasts',
        name: 'Podcasts',
        component: Podcasts,
    },
    {
        path: '/texts',
        name: 'Texts',
        component: Texts,
    },
    {
        path: '/videos',
        name: 'Videos',
        component: Videos,
    },
    {
        path: '/authors',
        name: 'Authors',
        component: Authors,
    },
    {
        path: '/cards',
        name: 'Cards',
        component: Cards,
    },
    {
        path: '/subscribes',
        name: 'Subscribes',
        component: Subscribes,
    },
    {
        path: '/pdfs',
        name: 'PDFs',
        component: Pdfs,
    },
    {
        path: '/banners',
        name: 'Banners',
        component: Banners,
    },
];

export const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});
