
import { Vue, Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Author } from '@umax/roditeli-types/entity';
import { CreateAuthorDto, UpdateAuthorDto } from '@umax/roditeli-types/dto';
import AuthorsCRUD from '@/components/modals/crud/AuthorsCRUD.vue';

const authors = namespace('Authors');

@Component({
    components: { AuthorsCRUD },
})
export default class Authors extends Vue {
    @authors.State((state) => state.authors)
    private readonly authors!: Author[];

    private crudModal = false;
    private columns = [
        {
            title: 'Имя',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            scopedSlots: { customRender: 'action' },
        },
    ];
    private currentId: string | null = null;

    @authors.Action
    createAuthor!: (data: CreateAuthorDto) => void;
    @authors.Action
    updateAuthor!: (data: { author: UpdateAuthorDto; id: string }) => void;
    @authors.Action
    fetchAuthors!: () => void;
    @authors.Action
    removeAuthor!: (id: string) => void;

    get currentAuthor(): Author | null {
        if (this.currentId) {
            return this.authors.find((author) => author.id === this.currentId) || null;
        }
        return null;
    }

    async mounted(): Promise<void> {
        try {
            await this.fetchAuthors();
        } catch (e: any) {
            this.$message.error('У вас недостаточно прав для этой страницы');
        }
    }

    private toggleModal() {
        if (this.crudModal) {
            this.currentId = null;
        }
        this.crudModal = !this.crudModal;
    }

    private async handleCreateAuthor(data: CreateAuthorDto) {
        try {
            await this.createAuthor(data);
            this.toggleModal();
            this.fetchAuthors();
        } catch (e: any) {
            this.$message.error(e.message);
        }
    }

    private async handleUpdateAuthor(data: UpdateAuthorDto) {
        if (this.currentId) {
            try {
                await this.updateAuthor({
                    author: data,
                    id: this.currentId,
                });
                this.toggleModal();
                this.fetchAuthors();
            } catch (e: any) {
                this.$message.error(e.message);
            }
        }
    }

    private async handleDeleteAuthor(id: string) {
        try {
            await this.removeAuthor(id);
            this.fetchAuthors();
        } catch (e: any) {
            const tableName = e.message.split('table')[1];
            this.$message.error(`Этот автор все еще используется в таблице ${tableName}`);
        }
    }

    private setCurrentId(id: string) {
        this.currentId = id;
    }

    @Watch('currentId')
    handleChange(val: string): void {
        if (val) {
            this.toggleModal();
        }
    }
}
