import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import axios from 'axios';
import { CreateTextDto, UpdateTextDto } from '@umax/roditeli-types/dto';
import { Text } from '@umax/roditeli-types/entity';

@Module({ namespaced: true })
export class Texts extends VuexModule {
    texts: Text[] = [];

    @Mutation
    setTexts(data: Text[]): void {
        this.texts = data;
    }

    @Action({ commit: 'setTexts', rawError: true })
    async fetchTexts(): Promise<void> {
        const texts = await axios.get('/admin/texts');
        return texts.data;
    }

    @Action({ rawError: true })
    async createText(data: CreateTextDto): Promise<void> {
        return axios.post('/admin/texts', data);
    }

    @Action({ rawError: true })
    async updateText(data: { text: UpdateTextDto; id: string }): Promise<void> {
        const { text, id } = data;
        return axios.patch('/admin/texts', text, {
            params: {
                id,
            },
        });
    }

    @Action({ rawError: true })
    async removeText(id: string): Promise<void> {
        return axios.delete(`/admin/texts/${id}`);
    }
}
