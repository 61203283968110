
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import axios from 'axios';
import { UploadFile } from 'ant-design-vue/types/upload';

@Component({})
export default class FileUploader extends Vue {
    @Prop({ default: 'image/*' })
    accept!: string;
    @Prop({ default: '/' })
    folder!: string;
    @Prop({ default: null })
    value!: string;

    private fileList: { name: string; uid: string }[] | null = [];
    private fileSize: string | null = null;
    private fileError = '';
    private tooBigSizeError = 'Размер файла должен быть менее 1Мб!';

    data() {
        return {
            uploadProps: {
                accept: this.accept,
                name: 'file',
                action: `/storage`,
                customRequest: this.customRequest,
                data: (file: UploadFile) => {
                    return {
                        path: this.folder,
                        size: file.size,
                        mimeType: file.type,
                        name: file.name,
                    };
                },
                beforeUpload: this.beforeUpload.bind(this),
            },
        };
    }

    mounted(): void {
        if (this.value) {
            const filePath = this.value.split('/');
            const fileName = filePath[filePath.length - 1];
            this.fileList = [{ name: fileName, uid: '1' }];
        }
    }

    private async customRequest(opts: {
        action: string;
        onSuccess: (name: string) => void;
        onError: (data: { err: Error }) => void;
        file: File;
        data: Record<string, string>;
    }): Promise<void> {
        const { action, onSuccess, onError, file, data } = opts;
        const formData = new FormData();
        const config = {
            headers: { 'Content-Type': 'multipart/form-data' },
        };
        formData.append('file', file);
        Object.keys(data).forEach((key) => {
            formData.set(key, data[key]);
        });
        try {
            const res = await axios.post(action, formData, config);
            onSuccess(res.data);
        } catch (err: any) {
            onError({ err });
        }
    }

    private beforeUpload(file: UploadFile): boolean {
        if (this.accept.includes('image')) {
            const fileSizeInMb = file.size / 1024 / 1024;
            if (fileSizeInMb > 1) {
                this.fileError = this.tooBigSizeError;
                return false;
            }
        }
        this.fileSize = (file.size / 1024).toFixed(2);
        return true;
    }

    private change(info: { file: UploadFile; fileList: UploadFile[] }) {
        if (!info) {
            this.fileList = [];
        } else if (info.file.status === 'done') {
            const fileName = info.file.response;
            this.fileList = [{ name: fileName, uid: '1' }];
            this.handleChange(this.getFileUrl(fileName));
        } else if (info.file.status === 'error') {
            this.$message.error(`${info.file.name} file upload failed.`);
        } else if (info.file.status === 'removed') {
            this.handleChange('');
            this.fileList = [];
        } else {
            this.fileList = [...info.fileList];
        }
    }

    @Emit('input')
    handleChange(url: string): string {
        return url;
    }
}
